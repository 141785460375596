import _ from "lodash";
import {
  EMPLOYMENT_FUNDING_ROUNDS_OPTIONS,
  DIVERSITY_OPTIONS,
  X_FACTOR_OPTIONS,
  AVG_RECENT_TENURE_OPTIONS,
  EARLY_AT_COMPANY_OPTIONS,
  ORIGIN_BASE_OPTIONS,
} from "@/constants/filter";

import store from "@/store/index";
import { CANDIDATE_STATUS_OPTIONS, EMP_GROWTH_RATE_OPTIONS, EMP_SIZE_OPTIONS } from "@/components/Filters/constants";

export const tokenText = ({ type, text, employments, showPrefix = true, route }) => {
  switch (type) {
    case "role-current": {
      if (!showPrefix) return text;

      return `Current title: ${text}`;
    }
    case "role-previous": {
      if (!showPrefix) return text;

      return `Title: ${text}`;
    }
    case "role": {
      if (!showPrefix) return text;

      const employment = employments?.find(it => it.role === text);
      let prefix = "Title";

      if (employment) {
        if (employment.index === 0) {
          prefix = "Current title";
        } else {
          prefix = "Title";
        }
      }

      return `${prefix}: ${text}`;
    }
    case "employer-current": {
      if (!showPrefix) return text;

      return `Current company: ${text}`;
    }
    case "employer-previous": {
      if (!showPrefix) return text;

      return `Company: ${text}`;
    }
    case "employer": {
      if (!showPrefix) return text;

      const employment = employments?.find(it => it.name === text);
      let prefix = "Company";

      if (employment) {
        if (employment.index === 0) {
          prefix = "Current company";
        } else {
          prefix = "Company";
        }
      }

      return `${prefix}: ${text}`;
    }
    case "emp-industry": {
      if (!showPrefix) return text;

      return `Industry: ${text}`;
    }
    case "ats-prospect-pool": {
      if (!showPrefix) return text;

      return `Greenhouse prospect pool: ${text}`;
    }
    case "edu-institution": {
      if (!showPrefix) return text;

      return `Education: ${text}`;
    }
    case "emp-tenure": {
      if (!showPrefix) return text;

      return `Tenure: ${text}`;
    }
    case "plain": {
      if (!showPrefix) return text;

      // This might be removed in Similarity
      return `Keyword: ${text}`;
    }
    case "diversity": {
      return _.find(DIVERSITY_OPTIONS, { value: text })?.label || text;
    }
    case "x-factor": {
      return _.find(X_FACTOR_OPTIONS, { value: text })?.label || text;
    }
    case "origin": {
      return _.find(
        [...ORIGIN_BASE_OPTIONS, { value: "ats", label: store.getters.currentUser.primaryTeamAtsName }],
        { value: text }
      )?.label || text;
    }
    case "status": {
      const list = CANDIDATE_STATUS_OPTIONS;

      if (route.name === "search" || route.name === "candidates") {
        list.unshift({
          value: "savedForLater",
          label: `Saved for later`,
        });
      }

      return _.find(
        list,
        { value: text }
      )?.label || text;
    }
    case "activity": {
      return _.find(
        store.getters.globalSearchObjectFilterOptions.activity,
        { id: text }
      )?.name || text;
    }
    case "channel": {
      const channels = [{ value: "outbound", label: "Outbound" }];

      if (store.getters.isReferralsEnabled) {
        channels.push({ value: "referrals", label: "Referral" });
      }

      if (store.getters.isInboundsEnabled) {
        channels.push({ value: "inbound", label: "Inbound" });
      }

      if (route.name !== "job-campaigns" && route.name !== "campaign-list") {
        channels.push({ value: "linkedin", label: "LinkedIn InMail" });
      }

      return _.find(
        channels,
        { value: text }
      )?.label || text;
    }
    case "sourcer": {
      const list = [
        { value: "all_teammates", label: "All teammates" },
        { value: "teamable", label: "Teamable" },
        ...store.getters.allTeammatesAndMe.map(item => ({
          value: item.id, label: item.name,
        })),
      ];

      return list.find(item => `${item.value}` === `${text}`)?.label || text;
    }
    case "avg-tenure-min": {
      return _.find(AVG_RECENT_TENURE_OPTIONS, { value: text })?.label || text;
    }
    case "location": {
      return text.split("|")[0];
    }
    case "avg-tenure-max": {
      return _.find(AVG_RECENT_TENURE_OPTIONS, { value: text })?.label || text;
    }
    case "emp-start-size": {
      return _.find(EMP_SIZE_OPTIONS, { value: text })?.label || text;
    }
    case "emp-end-size": {
      return _.find(EMP_SIZE_OPTIONS, { value: text })?.label || text;
    }
    case "emp-growth-rate": {
      return _.find(EMP_GROWTH_RATE_OPTIONS, { value: text })?.label || text;
    }
    case "emp-joined-min": {
      return _.find(EARLY_AT_COMPANY_OPTIONS, { value: text })?.label || text;
    }
    case "emp-joined-max": {
      return _.find(EARLY_AT_COMPANY_OPTIONS, { value: text })?.label || text;
    }
    case "emp-funding-round": {
      return _.find(EMPLOYMENT_FUNDING_ROUNDS_OPTIONS, { value: text }) || text;
    }
    case "emp-funding-round-latest": {
      return _.find(EMPLOYMENT_FUNDING_ROUNDS_OPTIONS, { value: text }) || text;
    }
    case "emp-funding-round-ever": {
      return _.find(EMPLOYMENT_FUNDING_ROUNDS_OPTIONS, { value: text }) || text;
    }
    default: {
      return text;
    }
  }
}
