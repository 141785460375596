import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { TITLE_REGEX } from "@/constants";

import CampaignListItemSearchPopover from "@/components/CampaignListItem/SearchPopover/Component";
import CandidateListItemSearchPopover from "@/components/CandidateListItem/SearchPopover/Component";
import ProjectListItemSearchPopover from "@/components/ProjectListItem/SearchPopover/SearchPopover";
import CampaignsApi from "@/common/http/CampaignsApi";
import ProfilesApi from "@/common/http/ProfilesApi";
import ProjectsApi from "@/common/http/ProjectsApi";

export default {
  name: "SearchResultsModal",
  components: {
    CampaignListItemSearchPopover,
    CandidateListItemSearchPopover,
    ProjectListItemSearchPopover,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
      debouncedSearchTerm: "",
      searchQuery: {},
      campaignCount: null,
      candidateCount: null,
      projectCount: null,
      campaignList: [],
      candidateList: [],
      projectList: [],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      // "globalQueryTerm",
    ]),
    innerVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      },
    },
    showRecentSearches() {
      return (!this.debouncedSearchTerm && this.currentUser.recentSearches?.length);
    },
    campaignCountLabel() {
      return this.campaignCount != null && this.campaignCount >= 0 ? this.campaignCount : "?";
    },
    candidateCountLabel() {
      return this.candidateCount != null && this.candidateCount >= 0 ? this.candidateCount : "?";
    },
    projectCountLabel() {
      return this.projectCount != null && this.projectCount >= 0 ? this.projectCount : "?";
    },
    currentContextPage() {
      if (this.$route.path.startsWith("/campaigns")) {
        return "campaigns";
      } else if (this.$route.path.startsWith("/projects")) {
        return "projects";
      }
      return null; // Don't care about other cases
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.innerVisible = false;
        });
      },
    },
    // globalQueryTerm: {
    //   immediate: true,
    //   handler(value) {
    //     this.searchTerm = value;
    //     this.debouncedSearchTerm = value;
    //   },
    // },
    innerVisible: {
      immediate: true,
      handler(isVisible) {
        if (isVisible) {
          this.$nextTick(() => {
            this.$refs.search.focus();
          });

          if (this.debouncedSearchTerm) {
            this.searchQuery = { query: this.debouncedSearchTerm };
          }
        }
      },
    },
    debouncedSearchTerm(val) {
      if (!this.innerVisible || !val) {
        return;
      }

      this.campaignCount = null;
      this.candidateCount = null;
      this.projectCount = null;
      this.campaignList = [];
      this.candidateList = [];
      this.projectList = [];
      this.searchQuery = { query: val };

      this.fetchCampaigns();
      this.fetchCandidates();
      this.fetchProjects();
    },
  },
  methods: {
    ...mapActions([
      "clearUserRecentSearch",
      "deleteUserRecentSearchTerm",
      "recordUserSearchTerm",
    ]),
    async fetchCampaigns() {
      const requestParams = { pageSize: 3, ...this.searchQuery };
      const { data } = await CampaignsApi.search(requestParams);
      this.campaignList = data.campaigns;
      this.campaignCount = data.count;
    },
    async fetchCandidates() {
      const requestParams = { pageSize: 3, ...this.searchQuery };
      const { data } = await ProfilesApi.search(requestParams);
      this.candidateList = data.profiles;
      this.candidateCount = data.count;
    },
    async fetchProjects() {
      const requestParams = { pageSize: 3, ...this.searchQuery };
      const { data } = await ProjectsApi.search(requestParams);
      this.projectList = data.projects;
      this.projectCount = data.count;
    },
    recordSearchAndCloseModal() {
      this.recordUserSearchTerm(this.debouncedSearchTerm);
      this.innerVisible = false;
    },
    searchRoute(tab = null) {
      const route = {
        name: "search",
        query: {
          query: this.debouncedSearchTerm,
        },
      };
      if (tab) {
        route.query.searchTab = tab;
      }

      return route;
    },
    deleteTerm(term) {
      this.deleteUserRecentSearchTerm({ user: this.currentUser, term });
    },
    clearAllTerms() {
      this.clearUserRecentSearch({ user: this.currentUser });
    },
    fillInRecentSearchTerm(term) {
      this.searchTerm = term;
      this.doSearch();
    },
    debounceAssignSearchTerm: _.debounce(function() {
      this.debouncedSearchTerm = this.searchTerm;
    }, 500),
    doSearch() {
      if (!this.isUserSudoing) {
        this.recordUserSearchTerm(this.searchTerm);
      }

      let query = { query: this.searchTerm };

      if (this.currentContextPage) {
        query.searchTab = this.currentContextPage;
      }

      this.innerVisible = false;
      this.$router.push({ name: "search", query });
    },
    clearSearch() {
      this.searchTerm = "";
      this.debouncedSearchTerm = "";
      this.$router.push({ name: "search" });
    },
    handleSearchKeydown(event) {
      if (!this.searchTerm) {
        return;
      }

      if (!this.searchTerm?.match(TITLE_REGEX)) {
        return;
      }

      if (event && event.key === "Enter") {
        this.doSearch();
      } else {
        this.debounceAssignSearchTerm();
      }
    },
  },
};
