<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useGetters, useActions } from "vuex-composition-helpers";

import logEvent from "@/common/Analytics";
import ProjectsApi from "@/common/http/ProjectsApi";

import ConnectedAtsJobModal from "@/components/ConnectedAtsJobModal/Component";

import {
  validateAtsJob,
  validateLocation,
  validateReviewer,
  validateName,
} from "./lib/validations";

import Step1 from "./components/Step1/Step1";
import Step2 from "./components/Step2/Step2";
import Step3 from "./components/Step3/Step3";
import Footer from "./components/Footer/Footer";

const {
  currentUser,
} = useGetters([
  "currentUser",
]);

const {
  updateCurrentUser,
} = useActions([
  "updateCurrentUser",
]);

const route = useRoute();
const router = useRouter();

const useModal = ref(false);
const projectForm = ref(null);
const step = ref(1);
const project = ref({});
const hasAtsJob = ref(!!currentUser.value.primaryTeamAtsName);
const hasCandidateReviewer = ref(false);
const hasApplicantReviewer = ref(false);
const connectedAtsJob = ref(null);
const previousRouteQuery = ref(null);
const isLoadingOrCreatingNewProject = ref(false);
const rules = ref({
  name: [
    { required: true, message: "Please input a job name", trigger: "blur" },
    { validator: (rule, value, callback) => validateName(rule, value, callback), trigger: "blur" },
  ],
  location: [
    { validator: (rule, value, callback) => validateLocation(rule, value, callback, hasAtsJob.value), trigger: "blur" },
  ],
  atsJobId: [
    { validator: (rule, value, callback) => validateAtsJob(rule, value, callback, hasAtsJob.value), trigger: "blur" },
  ],
  outboundReviewerIds: [
    { validator: (rule, value, callback) => validateReviewer(rule, value, callback, hasCandidateReviewer.value), trigger: "blur" },
  ],
  inboundReviewerIds: [
    { validator: (rule, value, callback) => validateReviewer(rule, value, callback, hasApplicantReviewer.value), trigger: "blur" },
  ],
});

const visible = computed(() => {
  return route.query.modal === "new-project";
});

const title = computed(() => {
  if (step.value !== 3) {
    return "Create a new job";
  }

  return "";
});

const fetchNewProject = async () => {
  isLoadingOrCreatingNewProject.value = true;

  const { data } = await ProjectsApi.new();
  const query = { ...route.query };

  if (query.parentModal) {
    project.value = { ...data.project, createDefaultCampaign: false };
  } else {
    project.value = data.project;
  }

  // the problem: opening this modal after reload (only after reloading page), the text-editor doesn't accept value via paste
  // the solution: manually adding a value and then resetting it provokes the text-editor to be re-rendered, so it started to accept value via paste
  project.value.postingDescription = "<p> </p>";

  setTimeout(() => {
    project.value.postingDescription = "";
  }, 0)

  isLoadingOrCreatingNewProject.value = false;
};

const modalClosed = () => {
  project.value = {};
  step.value = 1;
  hasAtsJob.value = !!currentUser.value.primaryTeamAtsName;

  if (visible.value) {
    router.replace({ query: previousRouteQuery.value });
  }
};

const goToProject = (projectId) => {
  connectedAtsJob.value = null;
  step.value = 1;
  router.push({ name: "job-configuration", params: { projectId }, hash: "#integration-settings" });
}

watch(visible, (isVisible) => {
  if (isVisible) {
    logEvent("create-new-project-intent");
    fetchNewProject();
  }
  projectForm.value?.clearValidate();
}, { immediate: true });

watch(hasAtsJob, () => {
  projectForm.value?.clearValidate();
});

watch(route, () => {
  if (!visible.value) {
    previousRouteQuery.value = route.query;
  }
});

function recordCreationFlow() {
  if (useModal.value) {
    currentUser.value.gptProjectCreation = false;
    updateCurrentUser();
  }
}

</script>

<template>
  <el-dialog
    id="new-project-modal"
    :visible="visible"
    :title="title"
    :show-close="step === 3"
    :close-on-click-modal="false"
    append-to-body
    @close="modalClosed"
  >
    <el-form
      ref="projectForm"
      class="w-100"
      :model="project"
      :rules="rules"
      :disabled="isLoadingOrCreatingNewProject"
      label-position="top"
    >
      <el-checkbox
        v-if="step !== 3 && currentUser.gptProjectCreation"
        v-model="useModal"
      >
        Bypass the chat prompts and always use this form to create jobs
      </el-checkbox>

      <Step1
        v-if="step === 1"
        :project.sync="project"
        :connected-ats-job.sync="connectedAtsJob"
        :has-ats-job.sync="hasAtsJob"
      />

      <Step2
        v-else-if="step === 2"
        :project.sync="project"
        :has-candidate-reviewer.sync="hasCandidateReviewer"
        :has-applicant-reviewer.sync="hasApplicantReviewer"
      />

      <Step3
        v-else-if="step === 3"
        :project="project"
      />
    </el-form>

    <!-- Footer -->
    <Footer
      :step.sync="step"
      :project.sync="project"
      :has-candidate-reviewer="hasCandidateReviewer"
      :has-applicant-reviewer="hasApplicantReviewer"
      :has-ats-job="hasAtsJob"
      :project-form="projectForm"
      :is-loading-or-creating-new-project.sync="isLoadingOrCreatingNewProject"
      @created="recordCreationFlow"
      @modalClosed="modalClosed"
    />

    <!-- Modals -->
    <connected-ats-job-modal
      v-if="connectedAtsJob"
      :visible="true"
      :job="connectedAtsJob"
      @close="connectedAtsJob = null"
      @go-to-project="goToProject"
    />
  </el-dialog>
</template>
