<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import UserAvatar from "@/components/UserAvatar";
import DateTimeHelper from "@/common/DateTimeHelper";
import TasksApi from "@/common/http/TasksApi";

const props = defineProps({
  chatbotName: {
    type: String,
    required: true,
  },
  messages: {
    type: Array,
    required: true,
  },
  taskId: {
    type: Number,
    required: true,
  },
  completedAt: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  "close",
]);

const {
  currentCandidate,
} = useGetters([
  "currentCandidate",
]);

const completedAtComputed = computed(() => {
  return DateTimeHelper.MMMDDYYYY(props.completedAt);
});

function filteredBodies(bodies) {
  // excludes images or possibly other objects?
  return bodies.filter(b => typeof b === "string");
};

function closeModal() {
  emit("close");
};

function base64ToArrayBuffer(data) {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const downloadPDF = async () => {
  // Direct download (PDF link isn't open in a new tab)
  const { data } = await TasksApi.chatbotPdf(props.taskId);
  const pdfData = base64ToArrayBuffer(data.blob);
  const blob = new Blob([pdfData], { type: "application/pdf" })
  const elem = window.document.createElement("a")
  elem.href = window.URL.createObjectURL(blob)
  elem.download = data.filename
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}
</script>

<template>
  <el-dialog
    visible
    title="Chatbot transcript"
    append-to-body
    @close="closeModal"
  >
    <template #title>
      <div class="d-flex justify-content-between">
        <div>
          <div class="candidate-name">{{currentCandidate.name || 'Name Unknown'}}</div>
          <div class="chatbot">
            <span class="name">{{chatbotName}} transcript</span>
            <span class="completed-at">{{completedAtComputed}}</span>
          </div>
        </div>
        <el-button
          class="export-button mr-32 d-flex align-items-start"
          type="primary"
          plain
          @click="downloadPDF"
        >
          <span class="material-icons-outlined md-18">
            file_download
          </span>
          Export PDF
        </el-button>
      </div>
    </template>
    <el-divider class="mt-0" />

    <div class="content-container">
      <div
        v-for="(message, idx) in messages"
        :key="idx"
        class="message-wrapper d-flex align-items-top"
      >
        <UserAvatar
          v-if="message.authorType === 'contact'"
          size="small"
          :image-url="currentCandidate.avatarUrl"
          :full-name="currentCandidate.name || 'Name Unknown'"
          hide-name-label
          :custom-style="{ 'background-color': '#CCD4F7', 'color': '#5670E6' }"
        />
        <UserAvatar
          v-else-if="message.authorType === 'bot'"
          size="small"
          full-name="Humanly"
          hide-name-label
        />
        <div
          v-if="filteredBodies(message.body).length"
          class="message"
        >
          <div class="sender">
            {{ message.authorType === 'contact' ? currentCandidate.name || 'Name Unknown' : 'Humanly'}}
          </div>
          <div
            v-for="(content, i) in filteredBodies(message.body)"
            :key="i"
            class="content"
          >
            {{ content }}
          </div>
        </div>
    </div>

    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
  .content-container {
    margin-top: 24px;
  }

  .candidate-name {
    font-size: 18px;
    font-weight: 600;
  }

  .completed-at {
    color: $tf-font-ghost;
    font-size: 14px;
    margin-left: 16px;
  }

  .message-wrapper {
    align-items: start;
  }

  .message {
    margin-top: 4px;
    margin-left: 16px;
    margin-bottom: 16px;

    .sender {
      font-weight: 800;
      margin-bottom: 4px;
    }
  }

  .export-button {
    height: 48px;
    font-weight: bold;
  }
</style>
