import { mapGetters } from "vuex";

import UserMenu from "@/components/UserMenu";
import SudoUser from "./SudoUser";
import TopBarAlert from "./TopBarAlert";

import { PRICING_PAGE } from "@/constants"

export default {
  name: "TopBar",
  components: {
    SudoUser,
    TopBarAlert,
    UserMenu,
  },
  props: {
    layout: {
      type: String,
      required: true,
      default: "default",
    },
  },
  data() {
    return {
      isNoMailboxJustHidden: false,
      pricingPage: PRICING_PAGE,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentTeam",
      "isOnTrial",
      "isNoMailbox",
      "isUserSudoing",
    ]),
    isLayoutSimple() {
      return this.layout === "simple";
    },
    trialDaysLeft() {
      const days = this.currentUser.trialDaysLeft;
      return `${days} day${days > 1 ? "s" : ""}`;
    },
    showNoMailboxWarning() {
      return !this.isNoMailboxJustHidden && this.isNoMailbox && !localStorage.getItem(`ignoreNoMailboxWarning:${this.currentUser.id}`);
    },
  },
  methods: {
    confirmNoMailbox() {
      this.$confirm("Would you like to ignore this warning?", "Dismiss permanently?", {
        confirmButtonText: "Yes, don't show it again",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        this.isNoMailboxJustHidden = true;
        localStorage.setItem(`ignoreNoMailboxWarning:${this.currentUser.id}`, true)
      }).catch(() => {});
    },
  },
}
