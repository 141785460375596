<script setup>
import { computed } from "vue";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});

const statusTooltip = computed(() => {
  return props.project.isClosed ? "This job is closed" : "This job is open";
});

</script>

<template>
  <div class="project-list-item-search-popover w-100">
    <div>
      <div class="d-flex align-items-center clickable px-16 py-6">
        <router-link
          :to="{ name: 'job-root', params: { projectId: project.id } }"
          class="anchor-wrapper w-100"
        >
          <span class="d-flex align-items-center link">
            <el-tooltip :content="statusTooltip" :open-delay="500">
              <div class="relative">
                <img v-if="project.featuredJob" class="icon" :class="{ closed: project.isClosed }" :src="require('@/assets/images/icons/fire.svg')" />
                <img v-else class="icon" :class="{ closed: project.isClosed }" :src="require('@/assets/images/icons/briefcase.svg')" />
              </div>
            </el-tooltip>

            <el-tooltip :content="project.name" :open-delay="500">
              <span class="title ellipsis ml-12" :class="{ 'closed': project.isClosed }">
                {{ project.name }}
              </span>
            </el-tooltip>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-list-item-search-popover {
  color: $tf-font-dark;
  background-color: #ffffff;
  font-size: 16px;

  .icon {
    width: 16px;

    &.closed {
      filter: invert(88%) sepia(0%) saturate(60%) hue-rotate(178deg) brightness(94%) contrast(80%);
    }
  }

  .title {
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    &.closed {
      color: #4C4C5C;
    }
  }
}
</style>
