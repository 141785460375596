import { TITLE_REGEX } from "@/constants";

export const validateAtsJob = (rule, value, callback, hasAtsJob) => {
  if (hasAtsJob && !value) {
    callback(new Error("Please select a job"));
  } else {
    callback();
  }
};

export const validateReviewer = (rule, value, callback, hasReviewer) => {
  if (hasReviewer && !value.length) {
    callback(new Error("Please select a reviewer"));
  } else {
    callback();
  }
};

export const validateLocation = (rule, value, callback, hasAtsJob) => {
  if (!value && !hasAtsJob) {
    callback(new Error("Please select a location"));
  } else {
    callback();
  }
};

export const validateName = (rule, value, callback) => {
  if (!!value && !value?.match(TITLE_REGEX)) {
    callback(new Error("\" < > not allowed "));
  }
  callback();
};
