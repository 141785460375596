import Vue from "vue";
import VueRouter from "vue-router";
import { PRICING_PAGE } from "@/constants";

Vue.use(VueRouter);

const props = (mapping) => {
  return ({ params }) => {
    const nameType = Object.entries(mapping); // [[param1, Number], [param2, String]]
    const nameRouteParam = nameType.map(([name, fn]) => [name, fn(params[name])]); // [[param1, 1], [param2, "hello"]]
    const props = Object.fromEntries(nameRouteParam); // { param1: 1, param2: "hello" }

    return props;
  }
}

const routes = [
  {
    name: "root",
    path: "/",
    redirect: { name: "dashboard" },
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("@/views/Dashboard/Component"),
    meta: { title: "Teamable" },
    children: [
      {
        name: "user-send-as-permission-modal",
        path: "send-as",
        component: () => import("@/components/UserSendAsPermissionModal/Component"),
      },
      {
        name: "ats-connect-modal",
        path: "ats-connect",
        component: () => import("@/components/AtsConnectModal/Component"),
      },
    ],
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("@/views/SignIn"),
  },
  {
    name: "choose-role",
    path: "/choose-your-role",
    component: () => import("@/views/ChooseRole"),
    meta: { title: "Welcome to Teamable", layout: "onboarding" },
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("@/views/SignOut"),
  },
  {
    name: "search",
    path: "/search",
    component: () => import("@/views/SearchResults/Component"),
    meta: { title: "Search Results" },
  },
  {
    name: "sourcing",
    path: "/sourcing",
    component: () => import("@/views/SourcingRoot/SourcingRoot"),
    meta: { title: "Sourcing" },
    children: [
      {
        name: "sourcing-new",
        path: "new",
        component: () => import("@/views/HireGpt/Wizards/New/New"),
      },
      {
        name: "sourcing-edit",
        path: "edit/:uuid",
        component: () => import("@/views/HireGpt/Wizards/Edit/Edit"),
      },
    ],
  },
  {
    name: "job-root",
    path: "/jobs/:projectId",
    component: () => import("@/views/ProjectRoot/ProjectRoot"),
    props: props({ projectId: Number }),
    children: [
      {
        name: "job-candidates",
        path: "candidates",
        props: props({ projectId: Number }),
        redirect: { name: "job-pipeline" },
      },
      {
        name: "job-pipeline",
        path: "pipeline",
        component: () => import("@/views/ProjectRoot/ProjectPipeline/ProjectPipeline"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-todo",
        path: "todo",
        component: () => import("@/views/ProjectRoot/ProjectToDo/ProjectToDo"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-campaigns",
        path: "campaigns",
        component: () => import("@/views/ProjectRoot/ProjectCampaigns/ProjectCampaigns"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-campaign-root",
        path: "campaigns/:campaignId",
        component: () => import("@/views/CampaignRoot/Component"),
        props: props({ campaignId: Number }),
      },
      {
        name: "job-settings",
        path: "settings",
        redirect: { name: "job-configuration" },
        props: props({ projectId: Number }),
      },
      {
        name: "job-configuration",
        path: "configuration",
        component: () => import("@/views/ProjectRoot/Configuration/Configuration"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-for-approval",
        path: "for-approval",
        component: () => import("@/views/ProjectRoot/ProjectToDo/ProjectForApproval/ProjectForApproval"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-screening",
        path: "screening",
        component: () => import("@/views/ProjectRoot/ProjectToDo/ProjectScreening/ProjectScreening"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-saved-for-later",
        path: "saved-for-later",
        component: () => import("@/views/ProjectRoot/ProjectToDo/ProjectSavedForLater/ProjectSavedForLater"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-no-email",
        path: "no-email",
        component: () => import("@/views/ProjectRoot/ProjectToDo/ProjectNoEmail/ProjectNoEmail"),
        props: props({ projectId: Number }),
      },
      {
        name: "job-recommendations",
        path: "recommendations",
        props: props({ projectId: Number }),
        redirect: (to) => ({ name: "sourcing", query: { tab: "recommendations", projectId: to.params.projectId } }),
      },
      {
        name: "job-applicants",
        path: "applicants",
        props: props({ projectId: Number }),
        redirect: (to) => ({ name: "sourcing", query: { tab: "applicants", projectId: to.params.projectId } }),
      },
    ],
  },
  {
    name: "job-list",
    path: "/jobs",
    component: () => import("@/views/ProjectList/Component"),
    meta: { title: "Jobs" },
  },
  {
    name: "campaign-list",
    path: "/campaigns",
    component: () => import("@/views/Campaigns/Campaigns"),
    meta: { title: "Campaigns" },
    redirect: { name: "all-campaigns" },
    children: [
      {
        meta: { title: "Campaigns" },
        name: "all-campaigns",
        path: "all",
      },
      {
        meta: { title: "Campaigns" },
        name: "outbound-campaigns",
        path: "outbound",
      },
      {
        meta: { title: "Campaigns" },
        name: "inbound-campaigns",
        path: "inbounds",
      },
      {
        meta: { title: "Campaigns" },
        name: "referrals-campaigns",
        path: "referrals",
      },
      {
        name: "aggregate-campaign-stats-modal",
        path: "stats",
      },
    ],
  },
  {
    name: "campaign-root",
    path: "/campaigns/:campaignId",
    component: () => import("@/views/CampaignRoot/Component"),
    props: props({ campaignId: Number }),
    children: [
      {
        name: "campaign-metrics",
        path: "metrics",
        component: () => import("@/views/CampaignRoot/CampaignMetrics/CampaignMetrics"),
      },
      {
        name: "campaign-template",
        path: "templates",
        redirect: { name: "campaign-messaging" },
      },
      {
        name: "campaign-settings",
        path: "settings",
        redirect: { name: "campaign-messaging" },
      },
      {
        name: "campaign-messages",
        path: "messages",
        component: () => import("@/views/CampaignRoot/CampaignMessaging/CampaignMessaging"),
        redirect: { name: "campaign-messaging" },
      },
      {
        name: "campaign-messaging",
        path: "messaging",
        component: () => import("@/views/CampaignRoot/CampaignMessaging/CampaignMessaging"),
      },
    ],
  },
  {
    name: "settings-root",
    path: "/settings",
    component: () => import("@/views/SettingsRoot/Component"),
    redirect: { name: "user-settings" },
    children: [
      {
        name: "user-settings",
        path: "user",
        component: () => import("@/views/SettingsRoot/UserSettings/Component"),
        meta: { title: "Settings" },
        children: [
          {
            name: "delete-my-account-modal",
            path: "delete-my-account",
            component: () => import("@/views/SettingsRoot/UserSettings/DeleteMyAccountModal"),
          },
        ],
      },
      {
        name: "team-settings",
        path: "team",
        component: () => import("@/views/SettingsRoot/TeamSettings/Component"),
        meta: { title: "Settings" },
      },
      {
        name: "ats-settings",
        path: "integrations",
        alias: "ats",
        component: () => import("@/components/AtsConnect/Component"),
        meta: { title: "Settings" },
      },
      {
        name: "templates-settings",
        path: "templates",
        component: () => import("@/views/SettingsRoot/DefaultTemplate/DefaultTemplate"),
        meta: { title: "Settings" },
      },
    ],
  },
  {
    name: "team-management-root",
    path: "/team-management",
    component: () => import("@/views/TeamManagementRoot/Component"),
    redirect: { name: "users-management" },
    children: [
      {
        name: "users-management",
        path: "users",
        component: () => import("@/views/TeamManagementRoot/TeamManagementUsers/Component"),
        meta: { title: "Team Management" },
      },
      {
        name: "invitations-management",
        path: "invitations",
        component: () => import("@/views/TeamManagementRoot/TeamManagementInvitations/Component"),
        meta: { title: "Team Management" },
      },
      {
        name: "access-requests-management",
        path: "access-requests",
        component: () => import("@/views/TeamManagementRoot/TeamManagementUsers/Component"),
        meta: { title: "Team Management" },
      },
    ],
  },
  {
    path: "/subscriptions",
    component: () => import("@/views/Subscriptions/Component"),
    children: [
      {
        name: "subscriptions",
        path: "",
        component: () => import("@/views/Subscriptions/SubscriptionSettings/Component"),
        meta: { title: "Subscriptions" },
      },
    ],
  },
  {
    name: "review",
    path: "/review",
    component: () => import("@/views/Review/ReviewRoot"),
    meta: { title: "Review" },
    children: [
      {
        name: "review-approval",
        path: "approval",
        component: () => import("@/views/Review/ProjectsForApproval"),
        meta: { title: "Review" },
      },
      {
        name: "review-applicants",
        path: "applicants",
        component: () => import("@/views/Review/ProjectsWithApplicants"),
        meta: { title: "Review" },
      },
      {
        name: "review-requested",
        path: "requested",
        component: () => import("@/views/Review/ProjectsWithOpenRequests"),
        meta: { title: "Review" },
      },
    ],
  },
  {
    name: "review-requested-project",
    path: "/review/project/:projectId/requested",
    component: () => import("@/views/ReviewRequested"),
    props: props({ projectId: Number }),
  },
  {
    name: "shared-candidates",
    path: "/shared-candidates",
    component: () => import("@/views/SharedCandidateList"),
  },
  {
    name: "candidates",
    path: "/candidates",
    redirect: { name: "crm" },
  },
  {
    name: "crm",
    path: "/crm",
    component: () => import("@/views/Candidates/Candidates"),
    meta: { title: "CRM" },
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("@/views/ReportsRoot/ReportsRoot"),
    meta: { title: "Reports" },
    children: [
      {
        name: "explore",
        path: "explore",
        meta: { title: "Explore" },
        component: () => import("@/components/ReportChart"),
      },
      {
        name: "generated-report",
        path: "generated",
        component: () => import("@/views/ReportsRoot/views/GeneratedReport/GeneratedReport"),
        meta: { title: "Review" },
      },
    ],
  },
  {
    name: "leaderboard",
    path: "/reports/leaderboard",
    meta: { title: "Referrals leaderboard" },
    component: () => import("@/views/ReportsRoot/views/Leaderboard/Leaderboard"),
  },
  {
    name: "report",
    path: "/reports/:reportName?/:reportObjectType?/:reportObjectId?",
    component: () => import("@/components/ReportChart"),
    props: true,
  },
  {
    name: "example-campaigns",
    path: "/example-campaigns",
    component: () => import("@/views/ExampleCampaigns/Component"),
    meta: { title: "Example Campaigns" },
  },
  {
    name: "post-install-message",
    path: "/welcome",
    meta: { layout: "simple" },
    component: () => import("@/views/PostInstallMessage"),
  },
  {
    name: "waiting-room",
    path: "/waiting-room",
    meta: { layout: "simple" },
    component: () => import("@/views/WaitingRoom"),
  },
  {
    name: "choose-your-plan",
    path: "/choose-your-plan",
    beforeEnter() {
      window.location.replace(PRICING_PAGE);
    },
  },
  {
    name: "account-deactivated-message",
    path: "/account-deactivated",
    meta: { layout: "simple" },
    component: () => import("@/views/AccountDeactivatedMessage"),
  },
  {
    name: "trial-expired-message",
    path: "/trial-expired",
    meta: { layout: "simple" },
    component: () => import("@/views/TrialExpiredMessage"),
  },
  {
    name: "review-subscription",
    path: "/review-subscription",
    meta: { title: "Checkout", layout: "simple", skipAuthRedirect: true },
    component: () => import("@/views/CheckoutReview/Component"),
  },
  {
    name: "checkout-success",
    path: "/checkout-success/:checkoutSessionId",
    meta: { title: "Success" },
    component: () => import("@/components/CheckoutSuccessPage"),
  },
  {
    name: "platform-upgrade",
    path: "/platform-upgrade",
    component: () => import("@/components/PlatformUpgrade"),
    meta: { title: "Want an upgrade?" },
  },
  {
    name: "approve-platform-seat",
    path: "/approve-platform-seat",
    component: () => import("@/components/ApprovePlatformSeat"),
    meta: { title: "Approve seat" },
  },
  {
    name: "not-found",
    path: "*",
    component: () => import("@/views/NotFound"),
  },
];

const scrollBehavior = (to) => {
  if (to.hash) {
    return new Promise(resolve => {
      const scrollTo = () => {
        resolve(
          window.scrollTo({
            top: document.querySelector(to.hash)?.offsetTop || 0,
            behavior: "smooth",
          })
        );
      };

      setTimeout(scrollTo, 1000);
      setTimeout(scrollTo, 2000);
      setTimeout(scrollTo, 4000);
    });
  } else {
    return { top: 0 }
  }
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(() => {});
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    return scrollBehavior(to);
  },
});

export default router;
