import ContinueOnDesktop from "../ContinueOnDesktop/Component";
import SideMenu from "../SideMenu/SideMenu";
import TopBar from "../TopBar/Component";

export default {
  name: "LayoutDefaultFull",
  components: {
    ContinueOnDesktop,
    SideMenu,
    TopBar,
  },
};
