<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useGetters } from "vuex-composition-helpers";

import AtsLogo from "@/components/AtsLogo/AtsLogo";

const emit = defineEmits(["change"]);

defineExpose({
  clearSelection,
});

const props = defineProps({
  projectsCampaignsData: {
    type: Object,
    required: true,
    default: () => {
      return {
        projects: [],
        campaigns: [],
      }
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  defaultProjectId: {
    type: Number,
    default: null,
  },
  defaultProjectCampaignId: {
    type: Number,
    default: null,
  },
  projectSelectionDisabled: {
    type: Boolean,
    default: false,
  },
  cacheEnabled: {
    type: Boolean,
    default: true,
  },
  disabledProjectIds: {
    type: Array,
    default: () => [],
  },
  showCampaigns: {
    type: Boolean,
    default: true,
  },
  showMineOnly: {
    type: Boolean,
    default: true,
  },
  showCampaignsOnlyForDefaultProject: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Choose a job or campaign",
  },
  mineOnly: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: "default",
    validator: function (value) {
      return ["mini", "small", "default", "large"].includes(value);
    },
  },
});

const {
  isUserSudoing,
  isCampaignFirstMessageGenerating,
  isCampaignFollowUpGenerating,
  isGeneratingNewCampaignForInAppSourcing,
} = useGetters([
  "isUserSudoing",
  "isCampaignFirstMessageGenerating",
  "isCampaignFollowUpGenerating",
  "isGeneratingNewCampaignForInAppSourcing",
]);

const mineOnly = ref(props.mineOnly);
const filterValue = ref("");
const selectedProjectCampaign = ref(null);

const selectedProjectCampaignPrefixIcon = computed(() => {
  if (!selectedProjectCampaign.value) return "";

  if (selectedProjectCampaign.value.id === "save" || selectedProjectCampaign.value.campaigns) {
    return "work";
  }

  return "send";
});

const isSelectorDisabled = computed(() => {
  return (
    isCampaignFollowUpGenerating.value ||
    isCampaignFirstMessageGenerating.value ||
    isGeneratingNewCampaignForInAppSourcing.value
  );
});

const allProjects = computed(() => {
  const campaignsPerProject = {};
  const { projects, campaigns } = props.projectsCampaignsData;

  campaigns.forEach(campaign => {
    if (campaignsPerProject[campaign.projectId]) {
      campaignsPerProject[campaign.projectId].push(campaign);
    } else {
      campaignsPerProject[campaign.projectId] = [campaign];
    }
  });
  let projectCampaignCombo = projects.map(project => {
    return {
      campaigns: campaignsPerProject[project.id] || [],
      ...project,
    };
  });

  if (props.showMineOnly && mineOnly.value) {
    return filterMineOnly(projectCampaignCombo);
  } else {
    return projectCampaignCombo;
  }
});

const allProjectsAndCampaigns = computed(() => {
  return props.projectsCampaignsData.campaigns.concat(allProjects.value);
});

const projectList = computed(() => {
  if (props.projectSelectionDisabled && props.defaultProjectId) {
    return allProjects.value.filter(item => item.id === props.defaultProjectId);
  } else {
    return allProjects.value;
  }
})

const hasNoDataWhenMineOnly = computed(() => {
  return !props.projectsCampaignsData.projects.filter(item => item.isMine).length;
});

const mineOnlyTooltip = computed(() => {
  return hasNoDataWhenMineOnly.value ? "No jobs or campaigns created by me" : "Show only jobs and campaigns that I've created or sourced candidates to";
});

const filteredProjects = computed(() => {
  if (!filterValue.value) {
    return projectList.value;
  }

  const filteredProjects = [];

  projectList.value.forEach(project => {
    if (includesFilterValue(project.name) || (project.atsJobRequisitionId && includesFilterValue(project.atsJobRequisitionId))) {
      filteredProjects.push(project);
    } else {
      const campaignsPerProject = project.campaigns.filter(campaign => includesFilterValue(campaign.name));
      campaignsPerProject.length && filteredProjects.push({ ...project, campaigns: campaignsPerProject });
    }
  });

  return filteredProjects;
});

function includesFilterValue(value) {
  return value.toLowerCase().includes(filterValue.value.toLowerCase());
}

function filterMineOnly(projectCampaignCombo) {
  const filteredProjects = [];

  projectCampaignCombo.forEach(item => {
    if (item.isMine) {
      filteredProjects.push({ ...item, campaigns: item.campaigns.filter(campaign => campaign.isMine) });
    }
  });

  return filteredProjects;
}

function isProjectDisabled(project) {
  return props.projectSelectionDisabled || props.disabledProjectIds.some(id => id === project.id);
}

function campaignTooltip(campaign) {
  if (campaign.isDisabled) {
    return `Can't select this campaign because you don't have permission to send on ${campaign.senderName}'s behalf.`;
  }
  return `${campaign.name} - Sender ${campaign.senderName}`;
}

function projectOrCampaignSelectionChanged() {
  if (props.cacheEnabled && !isUserSudoing.value && selectedProjectCampaign.value) {
    localStorage.setItem("previousSelectedProjectCampaign", JSON.stringify(selectedProjectCampaign.value));
  }
  emit("change", selectedProjectCampaign.value || null); // || null -- so it would pass null instead of '' when selection is cleared
}

function setFilterValue(value) {
  filterValue.value = value.trim();
}

function resetFilterValue() {
  filterValue.value = "";
}

function atsJobRequisitionIdLastSixDigitsForProject({ atsJobRequisitionId }) {
  if (!atsJobRequisitionId) {
    return;
  }

  const requisitionIdLength = atsJobRequisitionId.length;
  return requisitionIdLength <= 6 ? atsJobRequisitionId : `...${atsJobRequisitionId?.substr(requisitionIdLength - 6)}`;
}

function mineOnlyChangeHandler(mineOnly) {
  if (props.cacheEnabled && !isUserSudoing.value) {
    localStorage.setItem("project-campaign-mine-only", mineOnly);
  }
  if (mineOnly && selectedProjectCampaign.value && !selectedProjectCampaign.isMine) {
    selectedProjectCampaign.value = null;
    projectOrCampaignSelectionChanged();
  }
}

function clearSelection() {
  selectedProjectCampaign.value = null;
  projectOrCampaignSelectionChanged();
};

watch(() => props.projectsCampaignsData, () => {
  if (!props.projectSelectionDisabled && hasNoDataWhenMineOnly.value) {
    mineOnly.value = false;
  }
});

onMounted(() => {
  if (props.defaultProjectId) {
    selectedProjectCampaign.value = allProjects.value.find(item => item.id === props.defaultProjectId);
  } else if (props.enabledCache && !isUserSudoing.value && localStorage.getItem("previousSelectedProjectCampaign")) {
    selectedProjectCampaign.value = allProjectsAndCampaigns.value.find(item => JSON.stringify(item) === localStorage.getItem("previousSelectedProjectCampaign"));
  } else if (props.defaultProjectCampaignId) {
    selectedProjectCampaign.value = allProjectsAndCampaigns.value.find(item => item.id === props.defaultProjectCampaignId);
  }

  if (selectedProjectCampaign.value) {
    projectOrCampaignSelectionChanged();
  }
});

</script>

<template>
  <el-select
    v-model="selectedProjectCampaign"
    class="project-and-campaign-selector"
    :placeholder="placeholder"
    clearable
    filterable
    value-key="id"
    :filter-method="setFilterValue"
    :loading="loading"
    :size="size"
    :disabled="isSelectorDisabled"
    no-data-text="No jobs or campaigns"
    @visible-change="resetFilterValue"
    @change="projectOrCampaignSelectionChanged"
  >
    <template v-if="selectedProjectCampaignPrefixIcon" #prefix>
      <span class="project-title">
        <span class="material-icons-round md-18">
          {{ selectedProjectCampaignPrefixIcon }}
        </span>

        <span v-if="!selectedProjectCampaign.projectId" class="ats-logo-for-title d-flex align-items-center">
          <AtsLogo v-if="selectedProjectCampaign.atsName" :ats-name="selectedProjectCampaign.atsName" :problem="selectedProjectCampaign.atsJobProblem" />
          <span v-if="selectedProjectCampaign.atsJobRequisitionId" class="ml-8">{{atsJobRequisitionIdLastSixDigitsForProject(selectedProjectCampaign)}}</span>
          <span v-if="selectedProjectCampaign.favorite" class="ml-8 mt-4 material-icons-round md-18 rotate-45-right">push_pin</span>
        </span>
      </span>
    </template>

    <slot></slot>

    <template v-if="showMineOnly">
      <el-tooltip
        :content="mineOnlyTooltip"
        :open-delay="500"
      >
        <el-switch
          v-model="mineOnly"
          class="pl-16"
          :disabled="hasNoDataWhenMineOnly"
          active-text="Mine only"
          @change="mineOnlyChangeHandler"
        />
      </el-tooltip>

      <el-divider class="my-8" />
    </template>

    <template v-for="project in filteredProjects">
      <el-option
        v-if="!showCampaignsOnlyForDefaultProject"
        :key="project.id + '_project'"
        class="project"
        :value="project"
        :disabled="isProjectDisabled(project)"
        :label="project.name"
      >
        <span class="d-flex align-items-center justify-content-between">
          <span class="d-flex align-items-center">
            <span class="material-icons-round md-18 mr-8">work</span>
            <span class="project-name">{{ project.name }}</span>
          </span>

          <span class="d-flex align-items-center">
            <AtsLogo v-if="project.atsName" :ats-name="project.atsName" :problem="project.atsJobProblem" />
            <span v-if="project.atsJobRequisitionId" class="ml-8">{{atsJobRequisitionIdLastSixDigitsForProject(project)}}</span>
            <span v-if="project.favorite" class="ml-8 material-icons-round md-18 rotate-45-right">push_pin</span>
          </span>
        </span>
      </el-option>

      <template v-if="showCampaigns">
        <el-option
          v-for="campaign in project.campaigns"
          :key="campaign.id + '_campaign'"
          :class="{ campaign: !showCampaignsOnlyForDefaultProject }"
          :value="campaign"
          :disabled="campaign.isDisabled"
          :label="campaign.name"
        >
          <el-tooltip :content="campaignTooltip(campaign)" :open-delay="500">
            <span class="d-flex align-items-center justify-content-between">
              <span class="d-flex align-items-center">
                <img
                  v-if="campaign.sourcingWorkflow === 'ops'" class="stream-icon"
                  :src="require('@/assets/images/icon-stream-blue.svg')"
                />
                <span v-else class="material-icons-round md-18">send</span>
                <span class="campaign-name ml-8">{{ campaign.name }}</span>
              </span>
              <span v-if="campaign.favorite" class="mr-8 material-icons-round md-18 rotate-45-right">push_pin</span>
            </span>
          </el-tooltip>
        </el-option>
      </template>
    </template>
  </el-select>
</template>

<style lang="scss" scoped>
.project-and-campaign-selector {
  width: 100%;
}

::v-deep .el-input__prefix {
  width: 100%;
  margin-top: 12px;
}

.project-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 55px;
  align-items: flex-start;
}

.ats-logo-for-title {
  margin-top: -3px;

  >span {
    line-height: normal;
  }
}

.project {
  font-weight: 900;

  .project-name {
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ats-logo {
    width: 24px;
    height: 24px;
  }
}

.campaign {
  padding-left: 40px;

  .campaign-name {
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .stream-icon {
    width: 18px;
    height: 18px;
  }
}
</style>
