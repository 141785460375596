<script setup>
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  ref,
  unref,
  watchEffect,
} from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoute, useRouter } from "vue-router/composables";

import { TITLE_REGEX } from "@/constants";
import UserSendAsPermissionRequestModal from "@/components/UserSendAsPermissionRequestModal/Component";
import MissingJobDescriptionNotification from "@/components/MissingJobDescriptionNotification";
import NoResultsForSelect from "@/components/NoResultsForSelect";
import { INVITE_NEW_TEAMMATE_SUBTITLE } from "@/constants";
import JobAndCampaignSelector from "@/components/JobAndCampaignSelector/JobAndCampaignSelector";

const replyHandlingTooltip =
  "When a different reply handler is chosen, replies won't appear in the sender's inbox. Teamable will send the handler a notification, and they may respond on behalf of the sender from the app.";
const sandAsTooltip =
  "We typically see a 2x return when candidates receive outreach from VPs, executives or hiring managers for the role. You can also choose to reply on their behalf so they don't have to take any action.";

const route = useRoute();
const router = useRouter();
const emit = defineEmits(["closed", "submit"]);

const formRef = ref();
const project = ref();
const senderRef = ref();
const campaignName = ref();
const requestSendAsTeammate = ref();
const requestingSendAsTeammate = ref();

const {
  currentUser,
  newCampaign,
  currentProject,
  sourceableProjectList,
  wasSourceableProjectListFetched,
} = useGetters([
  "currentUser",
  "newCampaign",
  "currentProject",
  "sourceableProjectList",
  "wasSourceableProjectListFetched",
]);

const form = reactive({
  name: unref(newCampaign).name,
  projectId: null,
  senderId: null,
  replyHandlerId: false,
});

const validateNameForNotAllowedCharacters = (rule, value, callback) => {
  if (!!value && !value?.match(TITLE_REGEX)) {
    callback(new Error('" < > not allowed '));
  } else {
    callback();
  }
};

const rules = reactive({
  name: [
    {
      required: true,
      message: "Please input a campaign name",
      trigger: "blur",
    },
    { validator: validateNameForNotAllowedCharacters, trigger: "blur" },
  ],
  projectId: [
    { required: true, message: "Please choose a job", trigger: "change" },
  ],
});

const isUserSendAsPermissionRequestModalVisible = computed(() => {
  return requestSendAsTeammate.value && route.query["send-as-teammate"] != null;
});

const sender = computed({
  get() {
    const campaign = unref(newCampaign);
    return campaign.teammateSenderOptions?.find(
      (item) => item.id.toString() === campaign.pseudoSenderId.toString()
    );
  },
  set(teammate) {
    const campaign = unref(newCampaign);
    const teammateId = parseInt(teammate.id?.replace("/drafts", ""));

    if (!teammate.canSendAs) {
      const query = Object.assign({}, route.query);

      query["send-as-teammate"] = teammateId;
      requestSendAsTeammate.value = teammate;
      router.push({ query });

      return;
    }

    campaign.pseudoSenderId = teammate.id;
    campaign.senderId = teammateId;
    campaign.replyHandlerId = teammateId;
    form.senderId = teammateId;
  },
});

const replyHandler = computed({
  get() {
    return unref(newCampaign).teammateReplyHandlerOptions?.find(
      (item) => item.id === unref(newCampaign).replyHandlerId
    );
  },
  set(teammate) {
    const campaign = unref(newCampaign);

    if (!teammate.canSendAs && campaign.senderId !== currentUser.value.id) {
      const query = Object.assign({}, route.query);

      query["send-as-teammate"] = teammate.id;
      requestSendAsTeammate.value = sender.value;
      requestingSendAsTeammate.value = teammate;
      router.push({ query });
      return;
    }

    campaign.replyHandlerId = teammate.id;
    form.replyHandlerId = teammate.id;
  },
});

const campaignProjectId = computed({
  get() {
    const campaign = unref(newCampaign);
    const query = Object.assign({}, route.query);

    if (
      wasSourceableProjectListFetched.value &&
      query.projectId &&
      !newCampaign.value.projectId
    ) {
      return +query.projectId;
    }

    return campaign.projectId;
  },
  set(id) {
    form.projectId = id;
    newCampaign.value.projectId = id;
  },
});

const newCampaignName = computed({
  get() {
    return form.name;
  },
  set(val) {
    form.name = val;
    newCampaign.value.name = val;
  },
});

const isProjectDisabled = computed(() => !!unref(currentProject).id);

const submitHandler = () =>
  formRef.value.validate(async (isValid) => {
    if (!isValid) return;
    emit("submit");
  });

const cancelHandler = () => emit("closed");

function onProjectChange(newProject) {
  campaignProjectId.value = newProject.id;
}

watchEffect(() => {
  const val = unref(campaignProjectId);
  const query = Object.assign({}, route.query);

  delete query.projectId;
  router.push({ query });
  form.projectId = val;
  newCampaign.value.projectId = val;
});

onMounted(async () => {
  const currentProjectId = unref(currentProject).id;

  if (
    unref(sourceableProjectList)
      .map((p) => p.id)
      .includes(currentProjectId)
  ) {
    newCampaign.value.projectId = currentProjectId;
    form.projectId = currentProjectId;
  }

  await nextTick();
  project?.value?.focus();
});

const inviteClickHandler = () => {
  senderRef.value.blur();
  router.replace({
    name: "invitations-management",
    query: {
      modal: "invite-user",
      parentModal: "new-campaign",
    },
  });
};
</script>

<template>
  <div>
    <!-- form -->
    <el-form
      ref="formRef"
      :model="form"
      label-position="top"
      class="w-100"
      hide-required-asterisk
      :rules="rules"
    >
      <!-- ATS Job and Stage -->
      <el-form-item v-if="!currentProject.id" prop="projectId" class="w-100">
        <div class="d-flex justify-content-between align-items-end">
          <span class="data-label">Job</span>
        </div>

        <JobAndCampaignSelector
          :projects-campaigns-data="{
            campaigns: [],
            projects: sourceableProjectList,
          }"
          :show-campaigns="false"
          :default-project-id="campaignProjectId"
          :project-selection-disabled="isProjectDisabled"
          placeholder="Select a parent job for your new campaign"
          @change="onProjectChange"
        />
      </el-form-item>

      <!-- Campaign Name -->
      <p class="data-label mt-16">Name</p>
      <el-form-item prop="name" class="w-100">
        <el-input
          ref="campaignName"
          v-model="newCampaignName"
          placeholder="Enter a name for your new campaign"
        >
        </el-input>
      </el-form-item>

      <!-- SOBO -->
      <div class="d-flex align-items-center">
        <p class="data-label">Send as</p>
        <el-tooltip :open-delay="500" :content="sandAsTooltip">
          <span class="material-icons-round md-14 cursor-help"
            >help_outline</span
          >
        </el-tooltip>
      </div>
      <el-form-item prop="senderId" class="w-100">
        <div class="d-flex justify-content-between">
          <el-select
            ref="senderRef"
            v-model="sender"
            class="w-100"
            value-key="id"
            filterable
          >
            <el-option
              v-for="teammate in newCampaign.teammateSenderOptions"
              :key="teammate.id"
              :label="teammate.label"
              :value="teammate"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ teammate.label }}</span>
                <span
                  v-if="!teammate.canSendAs"
                  class="material-icons-round md-14 cursor-help"
                  >person_off</span
                >
              </div>
            </el-option>

            <template slot="empty">
              <NoResultsForSelect
                class="w-100"
                title="No matches found"
                :subtitle="INVITE_NEW_TEAMMATE_SUBTITLE"
                action-name="Invite teammate"
                @actionClick="inviteClickHandler"
              />
            </template>
          </el-select>
        </div>
      </el-form-item>

      <!-- ROBO -->
      <div class="d-flex align-items-center">
        <p class="data-label">Reply handling</p>
        <el-tooltip :open-delay="500" :content="replyHandlingTooltip">
          <span class="material-icons-round md-14 cursor-help"
            >help_outline</span
          >
        </el-tooltip>
      </div>
      <el-form-item prop="replyHandlerId" class="w-100">
        <el-select
          v-model="replyHandler"
          class="w-100 mr-8"
          value-key="id"
          filterable
        >
          <el-option
            v-for="teammate in newCampaign.teammateReplyHandlerOptions"
            :key="teammate.id"
            :label="teammate.label"
            :value="teammate"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ teammate.label }}</span>
                <span
                  v-if="
                    !teammate.canSendAs &&
                    newCampaign.senderId !== currentUser.id
                  "
                  class="material-icons-round md-14 cursor-help"
                  >person_off</span
                >
              </div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>

      <MissingJobDescriptionNotification :project-id="campaignProjectId" />

      <!-- actions -->
      <div class="d-flex justify-content-end">
        <el-button type="text" class="mr-24" @click="cancelHandler"
          >Cancel</el-button
        >
        <el-button type="primary" @click="submitHandler">Create</el-button>
      </div>
    </el-form>

    <!-- Modals -->
    <UserSendAsPermissionRequestModal
      :show="isUserSendAsPermissionRequestModalVisible"
      :send-as-teammate="requestSendAsTeammate"
      :reply-handling-teammate="requestingSendAsTeammate"
    />
  </div>
</template>

<style scoped lang="scss">
.data-label {
  padding: 0;
  margin: 0;
  color: $tf-font-default;
  margin-right: 4px;
}

.ats-logo {
  width: 24px;
  height: 24px;
}

.empty-slot-wrapper {
  padding: 16px;

  .title {
    color: $black-90;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .subtitle {
    color: $black-90;
    font-size: 14px;
    margin-bottom: 16px;
  }
}
</style>
