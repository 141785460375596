<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoot } from "@/composables/misc/useRoot";
import { useRoute } from "vue-router/composables";

import { MAIN_APP_URL } from "@/common/Env";

import UserMenu from "@/components/UserMenu";
import SearchResultsModal from "./SearchResultsModal/Component";

const {
  currentUser,
  isAuthenticated,
  reviewForApprovalCount,
} = useGetters([
  "currentUser",
  "isAuthenticated",
  "reviewForApprovalCount",
]);

const root = useRoot();
const route = useRoute();

const isMac = /Mac/.test(navigator.userAgent);
const isWindows = /Win/.test(navigator.userAgent);
const isLinux = /Linux/.test(navigator.userAgent);

const isLiveSearchVisible = ref(false);
const activeIndex = ref("0");
const sudoOptions = ref([]);

const sideMenuItems = computed(() => {
  let items = [
    { title: "Jobs", route: { name: "job-list", keyword: "jobs" }, icon: "briefcase", children: [
      { title: "Review", route: { name: "review", keyword: "review" }, icon: "document-check" },
    ] },
    { title: "Source", route: { name: "sourcing", keyword: "sourcing" }, icon: "users" },
    { title: "Engage", icon: "bolt", children: [
      { title: "Campaigns", route: { name: "campaign-list", keyword: "campaigns" }, icon: "queue-list" },
      // { title: "Conversations", route: { name: "conversation-list", keyword: "conversations" }, icon: "chat-bubble-left-right" },
    ] },
    { title: "Talent", route: { name: "crm", keyword: "crm" }, icon: "funnel" },
    // { title: "Meetings", route: { name: "meetings", keyword: "meetings" }, icon: "calendar-days" },
    { title: "Reports", route: { name: "reports", keyword: "reports" }, icon: "chart-bar" },
  ];

  return items;
});

const searchSymbolPrefix = computed(() => {
  if (isMac) {
    return "⌘";
  } else if (isWindows || isLinux) {
    return "Ctrl";
  }
  return "";
});

const teamsListUrl = computed(() => {
  return `${MAIN_APP_URL}/admin/teams`;
});

const usersListUrl = computed(() => {
  return `${MAIN_APP_URL}/admin/users`;
});

const opsSourcingUrl = computed(() => {
  return `${MAIN_APP_URL}/worker/sourcing_roles?sourcer_id=${currentUser.value.id}`;
});

const adminUserUrl = computed(() => {
  return `${MAIN_APP_URL}/admin/users/stop_sudo?redirect=${MAIN_APP_URL}/admin/users/${currentUser.value.id}/edit`
});

const adminTeamUrl = computed(() => {
  return `${MAIN_APP_URL}/admin/users/stop_sudo?redirect=${MAIN_APP_URL}/admin/teams/${currentUser.value.primaryTeamId}/edit`
});

const reviewCountLabel = computed(() => {
  if (!isNaN(reviewForApprovalCount.value) && reviewForApprovalCount.value > 0) {
    return reviewForApprovalCount.value;
  }

  return null;
});

const filteredSudoOptions = computed(() => {
  return sudoOptions.value.filter(o => o.id?.toString() !== currentUser.value.id?.toString());
});

watch(route, () => {
  setActiveIndex();
}, { immediate: true, deep: true });

function setActiveIndex() {
  if (route.name === "dashboard") {
    activeIndex.value = "-1";
  } else {
    const index = sideMenuItems.value.findIndex(item => route.path.startsWith(`/${item.route?.keyword}`));
    activeIndex.value = index.toString();
  }
}

function sudoOptionLabel(option) {
  let str = `Sudo as ${option.role}`;
  if (option.name) {
    str = `${str} - ${option.name}`
  }
  return str;
}

function sudoAsLink(option) {
  return `${MAIN_APP_URL}/admin/users/${option.id}/sudo?redirect=${encodeURIComponent(window.location.href)}`;
}

async function copySudoLinkForPage() {
  const url = `${MAIN_APP_URL}/admin/users/${currentUser.value0.id}/sudo?redirect=${encodeURIComponent(window.location.href)}`;
  await navigator.clipboard.writeText(url);
  root.$notify({
    position: "bottom-right",
    message: "Sudo link copied",
    title: "Success",
    type: "success",
  });
}

function assignSudoOptions(options) {
  sudoOptions.value = options || [];
}

function keydownHandler(event) {
  const isValidCommand = (
    (isMac && event.metaKey && event.key.toLowerCase() === "k") ||
    ((isWindows || isLinux) && event.ctrlKey && event.key.toLowerCase() === "k")
  )

  let isMessageEditor = event.target?.className?.match(/ql-editor|el-input__inner|el-select__input|editable|new-value-input|el-textarea__inner/);
  isMessageEditor = isMessageEditor || event.target.formAction?.includes("templates");
  if (isValidCommand && !isMessageEditor) {
    event.preventDefault();
    isLiveSearchVisible.value = true;
  }
}

function onSearchClick() {
  isLiveSearchVisible.value = true;
}

onMounted(() => {
  window.addEventListener("keydown", keydownHandler);

  root.$bus.$on("sudo-options-change", assignSudoOptions);
});

onUnmounted(() => {
  window.removeEventListener("keydown", keydownHandler);

  root.$bus.$off("sudo-options-change", assignSudoOptions);
});
</script>

<template>
  <el-aside class="tf-side-menu">
    <router-link id="logo-wrapper" :to="{ name: 'root' }">
      <img class="h-100 px-8" src="@/assets/images/app-logo/teamable-humanly-logo.svg" alt="Teamable">
    </router-link>

    <div class="d-flex justify-content-between align-items-center pl-8 pr-0 mb-4">
      <div class="d-flex align-items-center mr-8">
        <img class="item-icon" :src="require('@/assets/images/icons/user-circle.svg')" />
        <UserMenu id="user-menu" class="py-12" />
      </div>

      <el-tooltip content="Press to search">
        <div class="search-icon-container" @click="onSearchClick">
          <img class="item-icon mr-4" :src="require('@/assets/images/icons/magnifying-glass.svg')" />
          <span>{{ searchSymbolPrefix }}</span>
          +
          <span class="mr-4">K</span>
        </div>
      </el-tooltip>
    </div>

    <SearchResultsModal
      v-if="isAuthenticated"
      :visible.sync="isLiveSearchVisible"
    />

    <div v-if="isAuthenticated" id="side-menu-content">
      <el-menu :default-active="activeIndex.toString()">
        <component
          v-for="(item, index) in sideMenuItems"
          :is="item.route ? 'router-link' : 'div'"
          :key="index.toString()"
          :to="item.route"
          class="anchor-wrapper"
        >
          <el-submenu
            v-if="item.children && item.children.length"
            :index="index.toString()"
          >
            <template #title>
              <div class="title w-100" :class="{ 'is-active': index == activeIndex }">
                <img class="item-icon" :src="require(`@/assets/images/icons/${item.icon}.svg`)" />
                <span>{{ item.title }}</span>
              </div>
            </template>

            <router-link
              v-for="(subItem, subItemIndex) in item.children"
              :key="subItemIndex.toString()"
              :to="subItem.route"
              class="anchor-wrapper tf-submenu"
            >
              <el-menu-item class="sub-menu-item" :index="`${subItemIndex}-${subItem.title}`">
                <img class="item-icon" :src="require(`@/assets/images/icons/${subItem.icon}.svg`)" />

                <span>{{ subItem.title }}</span>
                <el-badge v-if="subItem.title === 'Review'" :value="reviewCountLabel" />
                <el-badge v-if="subItem.title === 'Conversations'" :value="unreadConversationsCount" />
              </el-menu-item>
            </router-link>
          </el-submenu>

          <el-menu-item
            v-else
            class="relative"
            :index="index.toString()"
          >
            <img class="item-icon" :src="require(`@/assets/images/icons/${item.icon}.svg`)" />

            <template slot="title">
              <span>{{ item.title }}</span>
              <el-badge v-if="item.title === 'Review'" :value="reviewCountLabel" />
              <el-badge v-if="item.title === 'Conversations'" :value="unreadConversationsCount" />
            </template>
          </el-menu-item>
        </component>

        <template v-if="currentUser.actualUserIsInternal || currentUser.actualUserIsPartnerCsm">
          <el-divider />

          <template v-if="currentUser.actualUserInternalSourcingOnly">
            <a :href="opsSourcingUrl" target="_blank" class="anchor-wrapper">
              <el-menu-item index="-3">
                <img class="item-icon" :src="require('@/assets/images/icons/user-plus.svg')" />
                <span slot="title">Ops Sourcing</span>
              </el-menu-item>
            </a>
          </template>

          <template v-else>
            <a :href="teamsListUrl" target="_blank" class="anchor-wrapper">
              <el-menu-item index="-4">
                <img class="item-icon" :src="require('@/assets/images/icons/user-group.svg')" />
                <span slot="title">Teams Admin</span>
              </el-menu-item>
            </a>

            <a :href="usersListUrl" target="_blank" class="anchor-wrapper">
              <el-menu-item index="-5">
                <img class="item-icon" :src="require('@/assets/images/icons/user-group.svg')" />
                <span slot="title">Users Admin</span>
              </el-menu-item>
            </a>
          </template>

          <template v-if="currentUser.showAdminOptions">
            <a :href="adminUserUrl" target="_blank" class="anchor-wrapper">
              <el-menu-item index="-6">
                <img class="item-icon" :src="require('@/assets/images/icons/user-group.svg')" />
                <span slot="title">User page</span>
              </el-menu-item>
            </a>

            <a :href="adminTeamUrl" target="_blank" class="anchor-wrapper">
              <el-menu-item index="-7">
                <img class="item-icon" :src="require('@/assets/images/icons/user-group.svg')" />
                <span slot="title">Team page</span>
              </el-menu-item>
            </a>
          </template>

          <el-divider />

          <a
            v-for="(sudoOption, index) in filteredSudoOptions"
            :key="index"
            class="anchor-wrapper"
            :href="sudoAsLink(sudoOption)"
            target="_blank"
          >
            <el-menu-item :index="`-${index * 10}`" class="text-wrap">
              <img class="item-icon" :src="require('@/assets/images/icons/arrow-right-end-on-rectangle.svg')" />
              <span>{{ sudoOptionLabel(sudoOption) }}</span>
            </el-menu-item>
          </a>

          <a
            v-if="$route.path.includes('/reports')"
            href="https://justin.looker.com/login/"
            class="anchor-wrapper"
            target="_blank"
          >
            <el-menu-item index="-8">
              <img class="item-icon" :src="require('@/assets/images/icons/arrow-right-end-on-rectangle.svg')" />
              <span>Internal Looker login</span>
            </el-menu-item>
          </a>

          <el-menu-item index="-9" @click="copySudoLinkForPage">
            <img class="item-icon" :src="require('@/assets/images/icons/document-duplicate.svg')" />
            <span>Copy sudo link for page</span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </el-aside>
</template>

<style lang="scss">
.tf-side-menu {
  width: $tf-sidebar-width !important;
  height: 100vh;
  padding: 16px 8px;
  transition: all $tf-global-transition;
  font-family: $tf-app-font-family !important;
  overflow-y: auto;
  background-color: $beige-50;
  border-right: 1px solid $beige-100;

  #logo-wrapper {
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
    display: flex;
  }

  .item-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .search-icon-container {
    display: flex;
    align-items: center;
    padding: 4px;
    color: #4C4C5C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    background-color: $beige-100;
    border-radius: $tf-global-border-radius;
    cursor: pointer;
  }

  .el-menu {
    border-right: none;
    background-color: transparent;
    font-family: $tf-app-font-family !important;

    .el-menu-item, .el-submenu__title {
      height: 40px;
      padding-left: 8px !important;
      padding-right: 8px !important;
      display: flex;
      align-items: center;
      border-radius: $tf-global-border-radius;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-top: 8px;
      margin-bottom: 8px;

      .el-submenu__icon-arrow {
        margin: 0;
        position: initial;
      }

      &.sub-menu-item {
        padding-left: 44px !important;
      }
    }

    .router-link-active {
      .el-menu-item {
        background: linear-gradient(180deg, rgba(229, 233, 249, 0.50) 0%, #E5E9F9 100%);
        color: $blue-500;

        img {
          filter: invert(10%) sepia(93%) saturate(7178%) hue-rotate(246deg) brightness(69%) contrast(138%);
        }
      }

      .el-submenu {
        .el-submenu__title {
          background: linear-gradient(180deg, rgba(229, 233, 249, 0.50) 0%, #E5E9F9 100%);
          color: $blue-500;
        }

        .el-submenu__icon-arrow {
          color: $blue-500;
        }

        img {
          filter: invert(10%) sepia(93%) saturate(7178%) hue-rotate(246deg) brightness(69%) contrast(138%);
        }
      }
    }

    .tf-submenu {
      .sub-menu-item {
        background: transparent;
        color: black;

        img {
          filter: none;
        }
      }

      &.router-link-active {
        .sub-menu-item {
          background: linear-gradient(180deg, rgba(229, 233, 249, 0.50) 0%, #E5E9F9 100%);
          color: $blue-500;

          img {
            filter: invert(10%) sepia(93%) saturate(7178%) hue-rotate(246deg) brightness(69%) contrast(138%);
          }
        }
      }
    }
  }
}
</style>
