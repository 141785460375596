import { setAnalyticsUser } from "@/common/Analytics";
import CookieService from "@/common/CookieService";
import UsersApi from "@/common/http/UsersApi";
import UsersApiOldDomain from "@/common/http/UsersApiOldDomain";

const state = {
  user: {},
  team: {},
  adminUser: {},
  currentUserSettings: {},
  teamInvitations: [],
  allowedDomains: [],
  isAuthenticated: false,
  isCurrentUserSettingsLoading: false,
  lobbyPage: null,
  authError: null,
  flashNotice: {},
  showSendAsPermissionOptIn: false,
  userJustLoggedIn: false,
  // TODO: show NavigationMenu alert when user is still partially syncing
  isUserPartiallySyncing: false,
  teammates: [],
  mentionsTeammates: [],
  isExtensionInstalled: false,
  isSavingSettings: false,
  isChooseRoleNeeded: false,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentTeam(state) {
    return state.team;
  },
  vueBuildSha(state) {
    return state.user.vueBuildSha;
  },
  isReferralsEnabled(state) {
    return state.team.introsEnabled;
  },
  isInboundsEnabled(state) {
    return state.team.inboundsEnabledAndSupported;
  },
  isAtsCandidatesEnabled(state) {
    return !!state.team.atsName && state.team.atsCandidatesEnabled;
  },
  isChooseRoleNeeded(state) {
    return state.isChooseRoleNeeded;
  },
  isCurrentUserSettingsLoading(state) {
    return state.isCurrentUserSettingsLoading;
  },
  mentionsTeammates(state, getters) {
    if (!state.user.id) {
      return []
    }

    return getters.allTeammates.map(u => {
      return {
        id: u.id,
        value: u.handle,
        label: u.name,
      }
    })
  },
  isSavingSettings(state) {
    return state.isSavingSettings;
  },
  isOnTrial(state) {
    return !!state.user?.trialDaysLeft;
  },
  isNoMailbox(state) {
    return state.user?.isNoMailbox;
  },
  allTeammates(state) {
    return state.teammates.filter(t => !t.isCurrentUser && !t.isEmployee);
  },
  allTeammatesAndMe(state) {
    return state.teammates.filter(t => !t.isEmployee);
  },
  allTeammatesAndEmployeesAndMe(state) {
    return state.teammates;
  },
  allEmployeeTeammatesAndMe(state) {
    return state.teammates.filter(t => t.isEmployee);
  },
  allConnectedTeammatesAndEmployeesAndMe(state) {
    return state.teammates.filter(t => t.hasSocialNetwork);
  },
  currentUserSettings(state) {
    return state.currentUserSettings;
  },
  teamInvitations(state) {
    return state.teamInvitations;
  },
  allowedDomains(state) {
    return state.allowedDomains;
  },
  flashNotice(state) {
    return state.flashNotice;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isUserBeingSetup(state) {
    return state.isUserBeingSetup;
  },
  lobbyPage(state) {
    return state.lobbyPage;
  },
  authError(state) {
    return state.authError;
  },
  actualUser(state) {
    if (state.adminUser && state.adminUser.email) {
      return state.adminUser;
    } else {
      return state.user;
    }
  },
  isUserSudoing(state) {
    return !!state.user.adminUserEmail;
  },
  currentUserHasOnlyOneTeam(state) {
    return state.user.teamsCount === 1;
  },
  // TODO: show NavigationMenu alert when user is still partially syncing
  isUserPartiallySyncing(state) {
    return state.user.showPartialSyncNotice;
  },
  showSendAsPermissionOptIn(state, getters) {
    return !!state.user.sendAsPermissionModal && !getters.isUserSudoing;
  },
  inviteeName(state) {
    return state.user.sendAsPermissionModal?.inviteeName;
  },
  inviteeReference(state) {
    return state.user.sendAsPermissionModal?.inviteeRef;
  },
  userJustLoggedIn(state) {
    return state.userJustLoggedIn;
  },
  isExtensionInstalled(state) {
    return state.user.isExtensionInstalled;
  },
};

const actions = {
  async checkAuth(context, skipAuthRedirect = false) {
    if (context.state.isAuthenticated) {
      return;
    }

    let userToken = CookieService.getUserToken();
    let adminToken = CookieService.getAdminToken();

    if (userToken) {
      try {
        let userResp, teammatesResp;
        [userResp, teammatesResp] = await Promise.all([
          UsersApi.me(),
          // need to swallow an error from teammates call so it wouldn't override
          // the detailed error from "me" endpoint
          UsersApi.teammates().catch(() => {}),
          // the following call creates an authenticated session
          // on the old domain so that extension could continue to work
          UsersApiOldDomain.generateCookie(userToken, adminToken),
        ]);

        context.commit("setAuth", {
          user: userResp.data.user,
          team: userResp.data.team,
          teammates: teammatesResp.data.teammates,
        });
      } catch (e) {
        if (e?.status === 403 && e?.substatus) {
          switch (e.substatus) {
            case "not_authorized":
              if (!skipAuthRedirect) {
                context.commit("setAuthNeeded", {
                  message: "Please sign in before continuing.",
                  status: 401,
                });
              }
              return;
            case "scopes_missing":
              context.commit("setAuthNeeded", {
                message: "Please complete your sign in before continuing",
                status: 401,
              });
              return;
            case "choose_role":
              context.commit("setChoseRoleNeeded", true)
              return;
            case "wrong_role_employee":
              context.commit("setAuthNeeded", {
                message: "Redirecting...",
                status: 401,
              });
              return;
            case "not_activated":
              context.commit("setLobbyPage", "post-install-message");
              return;
            case "sit_in_waiting_room":
              context.commit("setLobbyPage", "waiting-room");
              return;
            case "plan_missing":
              // Auto redirects to PRICING_PAGE, see router/index
              context.commit("setLobbyPage", "choose-your-plan");
              return;
            case "deactivated":
              context.commit("setLobbyPage", "account-deactivated-message");
              return;
            case "trial_expired":
              context.commit("setLobbyPage", "trial-expired-message");
              return;
            case "wrong_role_candidate":
              context.commit("setLobbyPage", "candidate-message");
              return;
          }
        }
        if (!skipAuthRedirect) {
          context.commit("setAuthNeeded", { message: e.message, status: e.status });
        }
      }
    } else {
      if (!skipAuthRedirect) {
        context.commit("setAuthNeeded", { message: "Please sign in before continuing.", status: 401 });
      }
    }
  },
  async refreshCurrentUser({ getters, commit }) {
    // only use case currently to refresh user to track outreach counts
    if (getters.currentUser.numOutreachRemaining !== null) {
      const { data } = await UsersApi.me();
      commit("setUser", data.user);
    }
  },
  async fetchCurrentUserSettings({ commit }) {
    commit("setIsCurrentUserSettingsLoading", true);
    const { data } = await UsersApi.settings();
    commit("setIsCurrentUserSettingsLoading", false);
    commit("setCurrentUserSettings", data.settings);
  },
  async updateTeammate({ commit }, { teammate, user }) {
    await UsersApi.updateTeammate(teammate, { user });
    await actions.fetchCurrentUserSettings({ commit });
  },
  async fetchTeamInvitations({ commit }) {
    const { data } = await UsersApi.teamInvitations();
    commit("setTeamInvitations", data.invitations);
    commit("setAllowedDomains", data.allowedDomains);
  },
  async updateTeamInvitations({ getters, commit }, { email, role }) {
    const { data } = await UsersApi.inviteTeammate(email, role);

    const params = [
      ...getters.teamInvitations,
      {
        email: data.email,
        id: data.id,
        inviterUserId: getters.currentUser.id,
        inviterUserName: getters.currentUser.name,
        role,
        sentAt: data.createdAt,
        validUntil: data.validUntil,
      },
    ];

    commit("setTeamInvitations", params);
  },
  async revokeTeamInvitation({ getters, commit }, id) {
    await UsersApi.revokeInviteTeammate(id);
    commit("setTeamInvitations", getters.teamInvitations.filter(item => item.id !== id));
  },
  async updateCurrentUser({ getters }) {
    await UsersApi.update(getters.currentUser);
  },
  async updateCurrentUserSettings({ getters, commit }, { greenhouseKeyChanged = false, leverKeyChanged = false } = {}) {
    commit("setIsSavingSettings", true);

    const params = {
      ...getters.currentUserSettings,
      id: getters.currentUser.id,
    }

    if (!greenhouseKeyChanged) {
      delete(params.greenhouseApiKey);
    }
    if (!leverKeyChanged) {
      delete(params.leverApiKey);
    }

    const { data } = await UsersApi.updateSettings(params);
    commit("setCurrentUserSettings", data.settings);
    commit("setIsSavingSettings", false);
  },
  async addTeammatePermissionToSendAsCurrentUser(context, teammateId) {
    await UsersApi.userPermissionsAddTeammate(teammateId);
  },
  async removeTeammatePermissionToSendAsCurrentUser(context, teammateId) {
    await UsersApi.userPermissionsRemoveTeammate(teammateId);
  },
  async recordUserSearchTerm({ state }, term) {
    const isTermAlreadyInRecent = state.user.recentSearches.some(item => item.trim().toLowerCase() === term.trim().toLowerCase());
    if (term && !isTermAlreadyInRecent) {
      UsersApi.recordSearch({ term });
      state.user.recentSearches.unshift(term);
      if (state.user.recentSearches.length > 10) {
        state.user.recentSearches.pop();
      }
    }
  },
  async deleteUserRecentSearchTerm(context, { user, term }) {
    UsersApi.clearRecentSearch({ term });
    const idx = user.recentSearches.findIndex(t => t === term);
    user.recentSearches.splice(idx, 1);
  },
  async clearUserRecentSearch(context, { user }) {
    UsersApi.clearRecentSearch({ clearAll: true });
    user.recentSearches = [];
  },
  async fetchCustomReportList({ commit }) {
    const { data } = await UsersApi.refreshCustomReportList();
    commit("setCustomReportList", data.customReportList);
  },
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setAuth(state, { user, team, teammates }) {
    state.isAuthenticated = true;
    state.user = user;
    state.team = team;
    state.teammates = teammates;
    state.adminUser = {
      id: user.adminUserId,
      uuid: user.adminUserUuid,
      name: user.adminUserName,
      email: user.adminUserEmail,
    };
    state.authError = null;
    setAnalyticsUser(getters.actualUser(state), team);
  },
  setTeam(state, team) {
    state.team = team;
  },
  setAuthNeeded(state, { message, status }) {
    state.isAuthenticated = false;
    state.user = {};
    state.team = {};
    message ||= "Auth needed";
    state.authError = { message, status };
    setAnalyticsUser(null);
  },
  setUserJustLoggedIn(state, value) {
    state.userJustLoggedIn = value;
  },
  setCurrentUserTimezone(state, value) {
    state.user.timezone = value;
  },
  setCurrentUserSettings(state, settings) {
    state.currentUserSettings = settings;
  },
  setIsCurrentUserSettingsLoading(state, value) {
    state.isCurrentUserSettingsLoading = value;
  },
  setTeamInvitations(state, teamInvitations) {
    state.teamInvitations = teamInvitations;
  },
  setAllowedDomains(state, allowedDomains) {
    state.allowedDomains = allowedDomains;
  },
  setCurrentUserSourceAsUserIds(state, id) {
    state.currentUserSettings.grantedSourceAsUserIds.push(id);
  },
  setCurrentUserSendAsUserIds(state, id) {
    state.currentUserSettings.grantedSendAsUserIds.push(id);
  },
  setCurrentUserTeammateCanSendAs(state, teammateId) {
    const teammate = state.teammates.find(user => user.id == teammateId);
    teammate.canSendAsCurrentUser = true;
  },
  setSeenSendAsPermissionModal(state) {
    state.user.showSendAsPermissionModal = false;
  },
  setCustomReportList(state, value) {
    state.user.customReportList = value;
  },
  setLobbyPage(state, value) {
    state.lobbyPage = value;
  },
  setReleaseNotesSeen(state) {
    state.user.releaseNotes = [];
  },
  setIsSavingSettings(state, value) {
    state.isSavingSettings = value;
  },
  setChoseRoleNeeded(state, value) {
    state.isChooseRoleNeeded = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
